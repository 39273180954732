<template>
  <el-dialog
    :model-value="modelValue"
    width="700px"
    title="添加菜单"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :inline="true" :label-width="100">
      <el-form-item label="上级菜单" prop="parentId" style="width: 615px">
        <el-cascader
          v-model="form.parentId"
          :options="menus"
          :props="{
            checkStrictly: true,
            value: 'menuId',
            label: 'title',
            children: 'children'
          }"
          clearable
          style="width: 100%"
        />
      </el-form-item>
      <el-form-item label="菜单名称" prop="title">
        <el-input v-model="form.title" placeholder="请输入菜单名称"></el-input>
      </el-form-item>
      <el-form-item label="菜单类型" prop="menuType">
        <el-radio v-model="form.menuType" :label="0">菜单</el-radio>
        <el-radio v-model="form.menuType" :label="1">按钮</el-radio>
      </el-form-item>
      <el-form-item label="路由地址" prop="path">
        <el-input
          v-model="form.path"
          placeholder="请输入路由地址"
          v-bind:disabled="form.menuType === 1"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序编号" prop="sortNumber">
        <el-input-number
          v-model="form.sortNumber"
          :min="1"
          :max="100000"
          controls-position="right"
        />
      </el-form-item>
      <el-form-item label="权限标识" prop="authority">
        <el-input
          v-model="form.authority"
          placeholder="请输入权限标识"
          v-bind:disabled="form.menuType === 0"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { addSysMenu } from '@/api/sys-menu'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  menus: {
    type: Object,
    required: true
  },
  parentId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'menus', 'parentId', 'close'])

const formRef = ref(null)
const form = ref({
  parentId: props.parentId,
  title: '',
  path: '',
  menuType: 0,
  authority: '',
  sortNumber: 0,
  status: 1
})

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

/**
 * 提交表单
 */
const onSubmitClick = async () => {
  console.log(JSON.stringify(form.value))
  if (Array.isArray(form.value.parentId)) {
    form.value.parentId = form.value.parentId[0]
  }
  if (form.value.menuType === 0) {
    form.value.authority = ''
  } else {
    form.value.path = ''
  }
  await addSysMenu(form.value)
  ElMessage.success('添加成功')
  closed()
}
</script>

<style lang="scss" scoped></style>
